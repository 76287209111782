<template>
  <lobby-page
    v-if="lobbyIsActive"
    :addPlayerIsActive="addPlayerIsActive"
    :browserUniqueId="browserUniqueId"
    @toggleAddPlayerIsActive="toggleAddPlayerIsActive"
    @showBaseDialog="showBaseDialog"
    @proceedToSetup="proceedToSetup"
    @resetGame="resetGame"
  ></lobby-page>
  <base-dialog
    v-if="baseDialogActive"
    :dialogHeading="dialogHeading"
    :dialogText="dialogText"
  ></base-dialog>
  <winning-dialog
    v-if="winningDialogActive"
    :dialogHeading="dialogHeading"
    :dialogText="dialogText"
    @sendOk="sendOk"
  ></winning-dialog>
  <losing-dialog
    v-if="losingDialogActive"
    :dialogHeading="dialogHeading"
    :dialogText="dialogText"
    @sendOk="sendOk"
  ></losing-dialog>
  <setup-page
    v-if="setupPageIsActive"
    :player="player"
    :browserUniqueId="browserUniqueId"
    :opponentBrowserUniqueId="opponentBrowserUniqueId"
    @proceedToGame="proceedToGame"
    @resetGame="resetGame"
  ></setup-page>
  <game-page
    v-if="gamePageIsActive"
    :browserUniqueId="browserUniqueId"
    :opponentBrowserUniqueId="opponentBrowserUniqueId"
    @showWinningDialog="showWinningDialog"
    @showLosingDialog="showLosingDialog"
    @resetGame="resetGame"
  ></game-page>
  <nav-page @resetGame="resetGame"></nav-page>
</template>

<script>
// import NavPage from "./components/NavPage.vue";
import LobbyPage from "./components/LobbyPage.vue";
import SetupPage from "./components/SetupPage.vue";
import GamePage from "./components/GamePage.vue";

export default {
  components: {
    // NavPage,
    LobbyPage,
    SetupPage,
    GamePage,
  },

  data() {
    return {
      addPlayerIsActive: true,
      browserUniqueId: null,
      baseDialogActive: false,
      dialogHeading: "",
      dialogText: "",
      gamePageIsActive: false,
      lobbyIsActive: true,
      losingDialogActive: false,
      opponentBrowserUniqueId: null,
      player: {
        activeTurn: false,
        name: "Tuomas",
        browserUniqueId: null,
        board: [],
        ship1Life: 1,
        ship2Life: 2,
        ship3Life: 3,
        ship4Life: 4,
        ship5Life: 5,
      },
      setupPageIsActive: false,
      winningDialogActive: false,
    };
  },
  methods: {
    resetGame() {
      console.log("reset the game!");
      this.addPlayerIsActive = true;
      this.deleteAllCandidatesFromDb();
      this.deleteAllPlayersFromDb();
      this.baseDialogActive = false;
      this.dialogHeading = "";
      this.dialogText = "";
      this.gamePageIsActive = false;
      this.lobbyIsActive = true;
      this.losingDialogActive = false;
      this.opponentBrowserUniqueId = null;
      this.player.name = null;
      this.player.browserUniqueId = null;
      this.player.board = [];
      this.setupPageIsActive = false;
      this.ship1Life = 1;
      this.ship2Life = 2;
      this.ship3Life = 3;
      this.ship4Life = 4;
      this.ship5Life = 5;
      this.winningDialogActive = false;
    },
    proceedToSetup(candidates) {
      console.log("received to roceedToSetup emit");
      this.lobbyIsActive = false;
      console.log(candidates);
      this.player.browserUniqueId = this.browserUniqueId;
      this.player.name = candidates.find(
        (el) => el.browserUniqueId === this.browserUniqueId
      ).name;
      this.opponentBrowserUniqueId = candidates.find(
        (el) => el.browserUniqueId != this.browserUniqueId
      ).browserUniqueId;
      this.setupPageIsActive = true;
      console.log(this.player);
    },
    proceedToGame(board, activeTurn) {
      this.player.board = board;
      this.player.activeTurn = activeTurn;
      this.setupPageIsActive = false;
      this.gamePageIsActive = true;
      this.insertPlayerIntoDb();
    },

    async deleteAllCandidatesFromDb() {
      try {
        const response = await fetch("/api/candidates", {
          method: "DELETE",
        });
        if (!response.ok) {
          const message = "Fetch response error: " + response.status;
          throw new Error(message);
        }
        return;
      } catch (error) {
        console.log(error);
      }
    },

    async deleteAllPlayersFromDb() {
      try {
        const response = await fetch("/api/players", {
          method: "DELETE",
        });
        if (!response.ok) {
          const message = "Fetch response error: " + response.status;
          throw new Error(message);
        }
        return;
      } catch (error) {
        console.log(error);
      }
    },

    async insertPlayerIntoDb() {
      try {
        const response = await fetch("/api/players", {
          method: "POST",
          body: JSON.stringify(this.player),
          headers: {
            "Content-type": "application/json",
          },
        });
        if (!response.ok) {
          const message = "Fetch response error: " + response.status;
          throw new Error(message);
        }
        console.log(response);
        const player = await response.json();
        return player;
      } catch (error) {
        console.log("Fetch catch error: " + error);
      }
    },
    sendOk() {
      this.winningDialogActive = false;
      this.losingDialogActive = false;
      this.resetGame();
    },
    setBrowserUniqueId() {
      this.browserUniqueId = Math.floor(Math.random() * 10000000);
      this.player.browserUniqueId = this.browserUniqueId;
    },
    showBaseDialog(heading, text) {
      this.dialogHeading = heading;
      this.dialogText = text;
      this.baseDialogActive = true;
    },
    showWinningDialog(heading, text) {
      this.dialogHeading = heading;
      this.dialogText = text;
      this.winningDialogActive = true;
    },
    showLosingDialog(heading, text) {
      this.dialogHeading = heading;
      this.dialogText = text;
      this.losingDialogActive = true;
    },
    toggleAddPlayerIsActive(val) {
      this.addPlayerIsActive = val;
    },
  },
  async created() {
    this.setBrowserUniqueId();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

* {
  box-sizing: border-box;
}

body {
  background-size: cover;
  background-image: url("./assets/Battleship.jpg");
  background-repeat: no-repeat;
}

h1 {
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px rgb(68, 69, 156);
  font-size: xx-large;
}

div {
  padding-left: 20px;
  padding-right: 20px;
}

.resetgamebutton {
  background-color: crimson;
  color: white;
  font-size: xx-large;
  border-radius: 10px;
}

.startgamebutton {
  background-color: rgb(22, 134, 22);
  color: white;
  font-size: xx-large;
  border-radius: 10px;
  margin: 20px;
}

.basicbutton {
  background-color: rgb(22, 29, 134);
  color: white;
  font-size: xx-large;
  border-radius: 10px;
  margin: 20px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.row {
  display: flex;
  width: 99%;
  padding: 0px;
}

.row2 {
  display: flex;
  width: 50%;
  padding: 0px;
}

.column1 {
  flex: 50%;
  border-radius: 12px;
  border: 1px black;
  margin: 10px;
  color: beige;
}

.column2 {
  flex: 50%;
  background-color: rgb(68, 69, 156);
  opacity: 0.7;
  border-radius: 12px;
  border: 1px black;
  margin: 10px;
  color: beige;
}

.board {
  background-color: rgb(68, 69, 156);
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  opacity: 0.9;
}

.boardsmall {
  background-color: rgb(68, 69, 156);
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  opacity: 0.9;
  margin-bottom: 20px;
}

.boardactive {
  background-color: rgb(68, 69, 156);
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  border: solid 5px rgb(81, 196, 81);
  opacity: 0.9;
}

.boardpassive {
  background-color: rgb(68, 69, 156);
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  border: solid 5px rgb(202, 48, 48);
  opacity: 0.9;
}

.squareH {
  height: 44px;
  border-radius: 10px;
}

.squareW {
  width: 44px;
  border: solid 1px whitesmoke;
}

.squareHHalf {
  height: 32px;
  border-radius: 10px;
}

.squareWHalf {
  width: 32px;
  border: solid 1px whitesmoke;
}

.squareWWhite {
  background-color: rgb(204, 204, 204);
  border: solid 1px rgb(68, 69, 156);
}

.squareWRed {
  background-color: red;
  border: solid 1px rgb(68, 69, 156);
}

.errormessage {
  color: red;
  font-weight: bold;
}

.playername {
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: xx-large;
  background-color: rgb(149, 197, 140);
  border-radius: 10px;
  border: black solid 1px;
  opacity: 0.9;
  margin-bottom: 10px;
}

.opponentname {
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: xx-large;
  background-color: rgb(156, 68, 83);
  border-radius: 10px;
  border: black solid 1px;
  opacity: 0.9;
  margin-bottom: 10px;
}

.cross {
  font-size: xx-large;
}

.crosssmall {
  font-size: large;
}

.statustexttop {
  text-align: left;
  color: black;
  font-size: large;
}

.statustextmiddle {
  text-align: left;
  color: rgb(138, 138, 138);
  font-size: large;
}

.statustextbottom {
  text-align: left;
  color: rgb(161, 179, 138);
  font-size: large;
}

.resetgamediv {
  padding: 20px;
}

.shiphealth {
  font-size: medium;
}
</style>
