<template>
  <div class="center">
    <div class="row center">
      <div class="column1 center">
        <div class="playername">
          {{ player.name ? player.name : "Odottaa..." }}<br />
          <span
            v-if="player.ship5Life > 0"
            class="shiphealth"
            style="color: black"
            >L:</span
          >
          <span class="shiphealth" v-for="el in player.ship5Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in playerShip5Hits"
            :key="el"
            >X</span
          >&nbsp;
          <span
            v-if="player.ship4Life > 0"
            class="shiphealth"
            style="color: black"
            >T:</span
          >
          <span class="shiphealth" v-for="el in player.ship4Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in playerShip4Hits"
            :key="el"
            >X</span
          >&nbsp;
          <span
            v-if="player.ship3Life > 0"
            class="shiphealth"
            style="color: black"
            >R:</span
          >
          <span class="shiphealth" v-for="el in player.ship3Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in playerShip3Hits"
            :key="el"
            >X</span
          >&nbsp;
          <span
            v-if="player.ship2Life > 0"
            class="shiphealth"
            style="color: black"
            >H:</span
          >
          <span class="shiphealth" v-for="el in player.ship2Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in playerShip2Hits"
            :key="el"
            >X</span
          >&nbsp;
          <span
            v-if="player.ship1Life > 0"
            class="shiphealth"
            style="color: black"
            >F:</span
          >
          <span class="shiphealth" v-for="el in player.ship1Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in playerShip1Hits"
            :key="el"
            >X</span
          >&nbsp;
        </div>
        <div class="playername">
          <span class="statustexttop">{{ statusText[0] }}</span
          ><br />
          <span class="statustextmiddle">{{ statusText[1] }}</span
          ><br />
          <span class="statustextbottom">{{ statusText[2] }}</span
          ><br />
        </div>
        <table class="boardsmall">
          <tr class="squareHHalf" v-for="hp in player.board" :key="hp">
            <td
              class="squareWHalf"
              :class="{
                squareWRed: wp.firedAt && wp.shipPresent,
                squareWWhite: !wp.firedAt && wp.shipPresent,
              }"
              v-for="wp in hp"
              :key="wp"
            >
              <span class="crosssmall">{{ wp.firedAt ? "x" : "" }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div class="column1 center">
        <div class="opponentname">
          {{ opponent.name ? opponent.name : "Odottaa..." }}

          <br />
          <span
            v-if="opponent.ship5Life > 0"
            class="shiphealth"
            style="color: black"
            >L:</span
          >
          <span class="shiphealth" v-for="el in opponent.ship5Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in opponentShip5Hits"
            :key="el"
            >X</span
          >&nbsp;
          <span
            v-if="opponent.ship4Life > 0"
            class="shiphealth"
            style="color: black"
            >T:</span
          >
          <span class="shiphealth" v-for="el in opponent.ship4Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in opponentShip4Hits"
            :key="el"
            >X</span
          >&nbsp;
          <span
            v-if="opponent.ship3Life > 0"
            class="shiphealth"
            style="color: black"
            >R:</span
          >
          <span class="shiphealth" v-for="el in opponent.ship3Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in opponentShip3Hits"
            :key="el"
            >X</span
          >&nbsp;
          <span
            v-if="opponent.ship2Life > 0"
            class="shiphealth"
            style="color: black"
            >H:</span
          >
          <span class="shiphealth" v-for="el in opponent.ship2Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in opponentShip2Hits"
            :key="el"
            >X</span
          >&nbsp;
          <span
            v-if="opponent.ship1Life > 0"
            class="shiphealth"
            style="color: black"
            >F:</span
          >
          <span class="shiphealth" v-for="el in opponent.ship1Life" :key="el"
            >O</span
          >
          <span
            class="shiphealth"
            style="color: red"
            v-for="el in opponentShip1Hits"
            :key="el"
            >X</span
          >&nbsp;
        </div>
        <table
          class="board"
          :class="{
            boardactive: this.activeTurn,
            boardpassive: !this.activeTurn,
          }"
        >
          <tr class="squareH" v-for="ho in opponent.board" :key="ho">
            <td
              @click="fireShip(wo.coordH, wo.coordW)"
              class="squareW"
              :class="{ squareWRed: wo.firedAt && wo.shipPresent }"
              v-for="wo in ho"
              :key="wo"
            >
              <span class="cross">{{ wo.firedAt ? "x" : "" }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="resetgamediv">
    <button class="resetgamebutton" @click="resetGame">RESETOI PELI</button>
  </div>
</template>

<script>
export default {
  props: ["browserUniqueId", "opponentBrowserUniqueId"],
  emits: ["showWinningDialog", "showLosingDialog", "resetGame"],
  data() {
    return {
      gameOver: false,
      opponent: {},
      player: {},
      pollOpponentFromDbActive: true,
      pollOpponentFromDbTimes: 50,
      pollPlayerFromDbActive: true,
      pollPlayerFromDbTimes: 200,
      sleepTime: 3000,
      statusText: ["Peli alkoi - Hyvää metsästysonnea!"],
    };
  },
  methods: {
    fireShip(y, x) {
      if (!this.gameOver) {
        if (this.activeTurn && this.opponent.board[y][x].firedAt === false) {
          this.opponent.board[y][x].firedAt = true;
          if (this.opponent.board[y][x].shipPresent === null) {
            this.statusText.unshift("");
            this.opponent.activeTurn = true;
            this.player.activeTurn = false;
            this.updatePlayerIntoDb();
          } else if (this.opponent.board[y][x].shipPresent === 5) {
            this.opponent.ship5Life--;
            if (this.opponent.ship5Life === 0) {
              this.statusText.unshift("*** Osuma, lentotukialus upposi ***");
            } else {
              this.statusText.unshift("Osuma lentotukialukseen");
            }
          } else if (this.opponent.board[y][x].shipPresent === 4) {
            this.opponent.ship4Life--;
            if (this.opponent.ship4Life === 0) {
              this.statusText.unshift("*** Osuma, taistelulaiva upposi ***");
            } else {
              this.statusText.unshift("Osuma taistelulaivaan");
            }
          } else if (this.opponent.board[y][x].shipPresent === 3) {
            this.opponent.ship3Life--;
            if (this.opponent.ship3Life === 0) {
              this.statusText.unshift("*** Osuma, risteilijä upposi ***");
            } else {
              this.statusText.unshift("Osuma risteilijään");
            }
          } else if (this.opponent.board[y][x].shipPresent === 2) {
            this.opponent.ship2Life--;
            if (this.opponent.ship2Life === 0) {
              this.statusText.unshift("*** Osuma, hävittäjä upposi ***");
            } else {
              this.statusText.unshift("Osuma hävittäjään");
            }
          } else if (this.opponent.board[y][x].shipPresent === 1) {
            this.opponent.ship1Life--;
            if (this.opponent.ship1Life === 0) {
              this.statusText.unshift("*** Osuma, fregatti upposi ***");
            } else {
              this.statusText.unshift("Osuma fregattiin");
            }
          }
          this.updateOpponentIntoDb();
        }
      }
    },

    async pollOpponentFromDb() {
      for (let i = 0; (i = this.pollOpponentFromDbTimes); i++) {
        if (this.pollOpponentFromDbActive === false || this.opponent.board) {
          return;
        } else {
          this.opponent = await this.getOnePlayerFromDb(
            this.opponentBrowserUniqueId
          );
          await this.sleep(this.sleepTime);
        }
      }
    },
    async pollPlayerFromDb() {
      for (let i = 0; (i = this.pollPlayerFromDbTimes); i++) {
        if (this.pollPlayerFromDbActive === false) {
          return;
        } else {
          this.player = await this.getOnePlayerFromDb(this.browserUniqueId);
          await this.sleep(this.sleepTime);
        }
      }
    },

    async getOnePlayerFromDb(playerId) {
      try {
        const response = await fetch("/api/players/" + playerId);
        if (!response.ok) {
          const message = "Fetch response error: " + response.status;
          throw new Error(message);
        }
        const playerX = await response.json();
        return playerX;
      } catch (error) {
        console.log("Fetch catch error: " + error);
        return {};
      }
    },
    resetGame() {
      this.pollOpponentFromDbActive = false;
      this.pollPlayerFromDbActive = false;
      this.$emit("resetGame");
    },
    showWinningDialog() {
      this.$emit(
        "showWinningDialog",
        "VOITTO!",
        "Voitit pelin! Paina OK palataksesi alkuun!"
      );
    },
    showLosingDialog() {
      this.$emit(
        "showLosingDialog",
        "HÄVISIT :(",
        "Hävisit pelin. Paina OK palataksesi alkuun!"
      );
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async updatePlayerIntoDb() {
      try {
        const response = await fetch("/api/players/" + this.browserUniqueId, {
          method: "PUT",
          body: JSON.stringify(this.player),
          headers: {
            "Content-type": "application/json",
          },
        });
        if (!response.ok) {
          const message = "Fetch response error: " + response.status;
          throw new Error(message);
        }
        return;
      } catch (error) {
        console.log("Fetch catch error: " + error);
      }
    },
    async updateOpponentIntoDb() {
      try {
        const response = await fetch(
          "/api/players/" + this.opponentBrowserUniqueId,
          {
            method: "PUT",
            body: JSON.stringify(this.opponent),
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        if (!response.ok) {
          const message = "Fetch response error: " + response.status;
          throw new Error(message);
        }
        return;
      } catch (error) {
        console.log("Fetch catch error: " + error);
      }
    },
  },
  async created() {
    this.pollPlayerFromDb();
    this.pollOpponentFromDb();
  },
  computed: {
    playerShip5Hits() {
      if (this.player.ship5Life) {
        return 5 - this.player.ship5Life;
      } else {
        return 0;
      }
    },
    playerShip4Hits() {
      if (this.player.ship4Life) {
        return 4 - this.player.ship4Life;
      } else {
        return 0;
      }
    },
    playerShip3Hits() {
      if (this.player.ship3Life) {
        return 3 - this.player.ship3Life;
      } else {
        return 0;
      }
    },
    playerShip2Hits() {
      if (this.player.ship2Life) {
        return 2 - this.player.ship2Life;
      } else {
        return 0;
      }
    },
    playerShip1Hits() {
      if (this.player.ship1Life) {
        return 1 - this.player.ship1Life;
      } else {
        return 0;
      }
    },

    opponentShip5Hits() {
      if (this.opponent.ship5Life) {
        return 5 - this.opponent.ship5Life;
      } else {
        return 0;
      }
    },
    opponentShip4Hits() {
      if (this.opponent.ship4Life) {
        return 4 - this.opponent.ship4Life;
      } else {
        return 0;
      }
    },
    opponentShip3Hits() {
      if (this.opponent.ship3Life) {
        return 3 - this.opponent.ship3Life;
      } else {
        return 0;
      }
    },
    opponentShip2Hits() {
      if (this.opponent.ship2Life) {
        return 2 - this.opponent.ship2Life;
      } else {
        return 0;
      }
    },
    opponentShip1Hits() {
      if (this.opponent.ship1Life) {
        return 1 - this.opponent.ship1Life;
      } else {
        return 0;
      }
    },

    playerPointsLeft() {
      return (
        this.player.ship1Life +
        this.player.ship2Life +
        this.player.ship3Life +
        this.player.ship4Life +
        this.player.ship5Life
      );
    },
    opponentPointsLeft() {
      return (
        this.opponent.ship1Life +
        this.opponent.ship2Life +
        this.opponent.ship3Life +
        this.opponent.ship4Life +
        this.opponent.ship5Life
      );
    },
    pointDifference() {
      return this.opponentPointsLeft - this.playerPointsLeft;
    },
    opponentBoardLength() {
      if (this.opponent.board) {
        return this.opponent.board.length;
      } else {
        return 0;
      }
    },
    activeTurn() {
      return this.player.activeTurn;
    },
  },

  watch: {
    pointDifference: async function () {
      if (this.playerPointsLeft > 0 && this.opponentPointsLeft < 1) {
        this.pollOpponentFromDbActive = false;
        this.pollPlayerFromDbActive = false;
        this.gameOver = true;
        await this.sleep(3000);
        this.showWinningDialog();
      } else if (this.playerPointsLeft < 1 && this.opponentPointsLeft > 0) {
        this.pollOpponentFromDbActive = false;
        this.pollPlayerFromDbActive = false;
        this.gameOver = true;
        await this.sleep(3000);
        this.showLosingDialog();
      }
    },
    playerShip5Hits: function (val) {
      if (val === 0) {
        this.statusText.unshift("*** Vihollinen upotti lentotukialuksesi ***");
      } else {
        this.statusText.unshift("Vihollinen osui lentotukialukseesi");
      }
    },
    playerShip4Hits: function (val) {
      if (val === 0) {
        this.statusText.unshift("*** Vihollinen upotti taistelulaivasi ***");
      } else {
        this.statusText.unshift("Vihollinen osui taistelulaivaasi");
      }
    },
    playerShip3Hits: function (val) {
      if (val === 0) {
        this.statusText.unshift("*** Vihollinen upotti risteilijäsi ***");
      } else {
        this.statusText.unshift("Vihollinen osui risteilijääsi");
      }
    },
    playerShip2Hits: function (val) {
      if (val === 0) {
        this.statusText.unshift("*** Vihollinen upotti hävittäjäsi ***");
      } else {
        this.statusText.unshift("Vihollinen osui hävittäjääsi");
      }
    },
    playerShip1Hits: function (val) {
      if (val === 1) {
        this.statusText.unshift("*** Vihollinen upotti freggattisi ***");
      }
    },
  },
};
</script>

<style scoped></style>
