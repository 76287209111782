<template>
  <header><h1>* * * L A I V A N U P O T U S * * *</h1></header>
  <div class="row2 center">
    <div class="column2">
      <header class="playerlobbyheading">PELAAJAT</header>
      <div v-if="candidates.length > 0" class="playerdiv">
        <table class="playertable center">
          <tr v-for="el in candidates" :key="el">
            <td>
              <span class="playernamelist">{{ el.name }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div class="playerdiv" v-if="addPlayerIsActive && candidates.length < 2">
        <input
          type="text"
          class="playerinput"
          v-model="newCandidateNameInput"
          @keyup.enter="insertCandidateIntoDb"
        />
      </div>
      <button
        class="basicbutton"
        v-if="addPlayerIsActive && candidates.length < 2"
        @click="insertCandidateIntoDb"
      >
        LISÄÄ PELAAJA
      </button>
      <div v-if="candidates.length > 2">
        <h2>LIIKAA PELAAJIA - RESETOI PELI!</h2>
      </div>

      <div v-if="readyToProceed">
        <button class="startgamebutton" @click="proceedToSetup">
          ETEENPÄIN!
        </button>
      </div>
      <div v-if="gameIsFull">
        <h2>PELI ON TÄYNNÄ!</h2>
      </div>
    </div>
  </div>
  <div class="resetgamediv">
    <button class="resetgamebutton" @click="resetGame">RESETOI PELI</button>
  </div>
</template>

<script>
export default {
  props: ["addPlayerIsActive", "browserUniqueId"],
  emits: ["toggleAddPlayerIsActive", "showBaseDialog", "proceedToSetup"],
  data() {
    return {
      refreshTimeout: null,
      candidates: [],
      newCandidateNameInput: "",
      interval: null,
      sleepTime: 3000,
      candidatesFetchRepeats: 30,
      stopCandidatesRefresh: false,
    };
  },
  methods: {
    async getAllCandidatesFromDb() {
      try {
        const response = await fetch("/api/candidates");
        if (!response.ok) {
          const message = "Fetch response error: " + response.status;
          throw new Error(message);
        }
        const candidates = await response.json();
        return candidates;
      } catch (error) {
        console.log("Fetch catch error: " + error);
      }
    },
    async insertCandidateIntoDb() {
      if (this.newCandidateNameInput.trim() != "") {
        const newCandidate = {
          name: this.newCandidateNameInput.trim(),
          browserUniqueId: this.browserUniqueId,
        };

        try {
          const response = await fetch("/api/candidates", {
            method: "POST",
            body: JSON.stringify(newCandidate),
            headers: {
              "Content-type": "application/json",
            },
          });
          if (!response.ok) {
            const message = "Fetch response error: " + response.status;
            throw new Error(message);
          }
          const candidate = await response.json();
          this.updateAllCandidatesToArray();
          this.newCandidateNameInput = "";
          this.toggleAddPlayerIsActive();
          return candidate;
        } catch (error) {
          console.log("Fetch catch error: " + error);
        }
      }
    },
    proceedToSetup() {
      this.stopCandidatesRefresh = true;
      this.$emit("proceedToSetup", this.candidates);
    },

    async setAutoRefresh() {
      for (let i = 0; i < this.candidatesFetchRepeats; i++) {
        await this.sleep(this.sleepTime);
        this.updateAllCandidatesToArray();
        if (this.stopCandidatesRefresh) {
          return;
        }
      }
      this.$emit(
        "showBaseDialog",
        "PALVELINYHTEYS KATKENNUT!",
        "Peli ei ole enää aktiivinen - Paina selaimen refresh nappulaa!"
      );
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    resetGame() {
      this.newCandidateNameInput = "";
      this.$emit("resetGame");
    },
    toggleAddPlayerIsActive(val) {
      this.$emit("toggleAddPlayerIsActive", val);
    },
    async updateAllCandidatesToArray() {
      this.candidates = await this.getAllCandidatesFromDb();
    },
  },
  computed: {
    readyToProceed: function () {
      if (
        this.candidates.length === 2 &&
        this.candidates.find(
          (el) => el.browserUniqueId === this.browserUniqueId
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    gameIsFull: function () {
      if (
        this.candidates.length === 2 &&
        !this.candidates.find(
          (el) => el.browserUniqueId === this.browserUniqueId
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    candidates: function (candidates) {
      if (candidates.length === 0) {
        this.toggleAddPlayerIsActive(true);
      }
    },
  },

  created() {
    this.setAutoRefresh();
  },
  mounted() {
    this.updateAllCandidatesToArray();
  },
  unmounted() {},
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.playertable {
  text-align: left;
}

.playerlobbyheading {
  background-color: rgb(91, 104, 173);
  color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 10px;
  border: solid 1px black;
  font-size: xx-large;
  padding: 10px;
  margin: 10px;
}

.playerdiv {
  background-color: rgb(91, 104, 173);
  color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 10px;
  border: solid 1px black;
  font-size: x-large;
  padding: 10px;
  margin: 10px;
}

.playerinput {
  width: 80%;
  height: 50px;
  font-size: xx-large;
  text-align: center;
}
</style>
