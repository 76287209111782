<template>
  <div class="center">
    <div class="row center">
      <div class="column1">
        <div class="playername">{{ player.name }}</div>
        <table class="board">
          <tr class="squareH" v-for="h in board" :key="h">
            <td
              @click="setShip(w.coordH, w.coordW)"
              class="squareW"
              :class="{ squareWWhite: w.shipPresent }"
              v-for="w in h"
              :key="w"
            ></td>
          </tr>
        </table>
      </div>
      <div class="column2">
        <div>
          <h2>
            <span v-if="toBeSetShipSize === 5">Aseta lentotukialus.</span>
            <span v-if="toBeSetShipSize === 4">Aseta taistelulaiva.</span>
            <span v-if="toBeSetShipSize === 3">Aseta risteilijä.</span>
            <span v-if="toBeSetShipSize === 2">Aseta hävittäjä.</span>
            <span v-if="toBeSetShipSize === 1">Aseta fregatti.</span>
          </h2>
        </div>
        <div>
          <button
            v-if="toBeSetShipSize > 0"
            class="basicbutton"
            @click="toBeSetHorizontal = !toBeSetHorizontal"
          >
            Käännä laiva
          </button>
        </div>

        <table class="board" v-if="!toBeSetHorizontal">
          <tr class="squareH" v-for="hs in toBeSetShipSize" :key="hs">
            <td class="squareW squareWWhite"></td>
          </tr>
        </table>

        <table class="board" v-if="toBeSetHorizontal">
          <tr class="squareH">
            <td
              class="squareW squareWWhite"
              v-for="ws in toBeSetShipSize"
              :key="ws"
            ></td>
          </tr>
        </table>

        <div class="errormessage">{{ setupErrorMessage }}</div>

        <div v-if="toBeSetShipSize < 1">
          <button class="startgamebutton" @click="proceedToGame">
            ALOITA!
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="resetgamediv">
    <button class="resetgamebutton" @click="resetGame">RESETOI PELI</button>
  </div>
</template>

<script>
export default {
  props: ["player", "browserUniqueId", "opponentBrowserUniqueId"],
  emits: ["proceedToGame"],
  data() {
    return {
      activeTurn: null,
      boardHeight: 10,
      boardWidth: 10,
      setupErrorMessage: null,
      toBeSetHorizontal: true,
      toBeSetShipSize: 5,
      board: [],
    };
  },
  methods: {
    createEmptyBoard() {
      const playerBoard = [];
      let tempWidthArray = [];

      for (let h = 0; h < this.boardHeight; h++) {
        tempWidthArray = [];
        for (let w = 0; w < this.boardWidth; w++) {
          tempWidthArray.push({
            firedAt: false,
            shipPresent: null,
            coordW: w,
            coordH: h,
          });
        }

        playerBoard.push(tempWidthArray);
      }

      return playerBoard;
    },
    proceedToGame() {
      this.$emit("proceedToGame", this.board, this.activeTurn);
    },
    resetGame() {
      this.$emit("resetGame");
    },

    setShip(y, x) {
      if (this.toBeSetShipSize > 0) {
        this.setupErrorMessage = null;
        if (this.toBeSetHorizontal && x + this.toBeSetShipSize < 11) {
          for (let i = 0; i < this.toBeSetShipSize; i++) {
            if (this.board[y][x + i].shipPresent != null) {
              this.setupErrorMessage = "Laivoja ei saa asettaa päällekkäin!";
              return;
            }
          }
          for (let i = 0; i < this.toBeSetShipSize; i++) {
            this.board[y][x + i].shipPresent = this.toBeSetShipSize;
          }
        } else if (!this.toBeSetHorizontal && y + this.toBeSetShipSize < 11) {
          for (let i = 0; i < this.toBeSetShipSize; i++) {
            if (this.board[y + i][x].shipPresent != null) {
              this.setupErrorMessage = "Laivoja ei saa asettaa päällekkäin!";
              return;
            }
          }
          for (let i = 0; i < this.toBeSetShipSize; i++) {
            this.board[y + i][x].shipPresent = this.toBeSetShipSize;
          }
        } else {
          this.setupErrorMessage = "Laiva ei mahtunut pelialueelle";
          return;
        }
        this.toBeSetShipSize--;
      }
    },
  },
  created() {
    console.log("created");
    this.board = this.createEmptyBoard();
    if (this.browserUniqueId > this.opponentBrowserUniqueId) {
      this.activeTurn = true;
    }
  },
};
</script>

<style scoped></style>
