import { createApp } from "vue";
import App from "./App.vue";
import BaseDialog from "./components/UI/BaseDialog.vue";
import WinningDialog from "./components/UI/WinningDialog.vue";
import LosingDialog from "./components/UI/LosingDialog.vue";

const app = createApp(App);
app.component("base-dialog", BaseDialog);
app.component("winning-dialog", WinningDialog);
app.component("losing-dialog", LosingDialog);
app.mount("#app");
