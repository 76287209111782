<template>
  <div></div>
  <dialog open>
    <header>
      <h1>{{ dialogHeading }}</h1>
    </header>
    <section>{{ dialogText }}</section>
    <menu><button class="basicbutton center" @click="sendOk">OK</button></menu>
  </dialog>
</template>

<script>
export default {
  props: ["dialogHeading", "dialogText"],
  emits: ["sendOk"],
  methods: {
    sendOk() {
      this.$emit("sendOk");
    },
  },
};
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: 1px black;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

header {
  background-color: rgb(156, 68, 83);
  color: white;
  width: 100%;

  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
  background-color: rgb(255, 255, 255);
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
